@font-face {
    font-family: 'ProximaNova';
    src:  url('../fonts/ProximaNova-Light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    src:  url('../fonts/ProximaNova-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    src:  url('../fonts/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    src:  url('../fonts/ProximaNova-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    src:  url('../fonts/ProximaNova-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
