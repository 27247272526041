@import "assets/styles/fonts.css";
@import "~react-datepicker/dist/react-datepicker.css";

* {
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'ProximaNova', 'sans-serif',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1300px;
  font-size: 1rem;
  font-weight: 500;
  overflow-x: hidden;
}

.paper-select {
  border-radius: 10px!important;
  transition: none!important;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dcd9d9;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3b80c2;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3B80C2FF;
}
